/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    h3: "h3",
    strong: "strong",
    pre: "pre",
    code: "code",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Object destructuring the method of extracting properties at"), "\n", React.createElement(_components.h2, null, "The problem it solves"), "\n", React.createElement(_components.h3, null, "Examples"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Without destructuring")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "const car = {\n  color: 'blue',\n  model: 'ford',\n  make: 'mustange'\n  year: '1976'\n}\n\nconst color = car.color\nconst year = car.year\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "With desctructuring")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "const car = {\n  color: 'blue',\n  model: 'ford',\n  make: 'mustange'\n  year: '1976'\n}\n\nconst {color, model, make year} = car\n\nconsole.log(color)\n// blue\n")), "\n", React.createElement(_components.h3, null, "Renaming In Destructure"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "const car = {\n  color: 'blue',\n  model: 'ford',\n  make: 'mustange'\n  year: '1976'\n}\n\nconst { color: c, model: mo, make: ma, year: yr} = car\n\nconsole.log(mo)\n// ford\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, ";(() => {\n  // do stuff\n})()\n")), "\n", React.createElement(_components.h3, null, "References"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://wesbos.com/destructuring-objects"
  }, "Dead simple intro to destructuring javascript")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
